@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

.product-content {
  background: transparent;
}

#root {
  padding-top: $calculated-navbar-height;
  @include media-breakpoint-down(sm) {
    padding-top: $calculated-navbar-height-sm;
  }
}
.card.bg-light {
  background-color: $gray-800 !important;
  color: $white;
}

.checkout .when-valid {
  background-color: #000;
}

.navbar-light .offcanvas-collapse,
.navbar-dark .offcanvas-collapse {
  background: transparent;

  &.offcanvas-collapse-right {
    background: $gray-900;
  }

  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }
}
.navbar-dark {
  background: transparent;
}

.navbar-dark .offcanvas-collapse {
  .navbar-nav .nav-item .nav-link {
    background: $gray-900;
  }
}

.maki.card .card-body {
  padding: $card-spacer-x;

  .card-title .title {
    font-weight: 500;
  }
}

.table {
  @extend .table-dark;
}

.card,
.maki.card,
.navbar-light .offcanvas-collapse {
  color: $black;
}

#header > .navbar {
  height: $calculated-navbar-height;
  @include media-breakpoint-down(sm) {
    padding-top: 0.35rem;
    height: $calculated-navbar-height-sm;
  }

  .navbar-brand {
    @include media-breakpoint-up(md) {
      img {
        height: 2rem;
        max-height: 2rem;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
      }
    }
  }
}

#footer {
  color: $white;
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}
